@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

html {
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

html::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.App {
    --keyboard-accessibility-color: blue;

    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.accessible, .accessible-tight {
    transition: all .3s, outline 0s, outline-offset 0s !important;
}

.accessible-tight:focus-visible {
    outline: 2px var(--keyboard-accessibility-color) solid;
    outline-offset: -2px;
    border-radius: 3px;
}

.accessible:focus-visible {
    outline: 2px var(--keyboard-accessibility-color) solid;
    outline-offset: 3px;
    border-radius: 3px;
}